let navEnabled = false;

$('header #dropDown').unbind().click(function () {
    if (navEnabled) {
        document.getElementById("navigation").style.maxHeight = "0";
        navEnabled = false;
    } else {
        document.getElementById("navigation").style.maxHeight = "999px";
        navEnabled = true;
    }
    // $('nav').toggleClass('toggle');
});

initHeaderCarousel();
initNav();

let map;

function initMap() {
    let location = {lat: 51.895870, lng: 4.160390};
    map = new google.maps.Map(document.getElementById('map'), {
        center: location,
        zoom: 15
    });

    let marker = new google.maps.Marker({
        position: location,
        map: map,
        title: 'depraktijk4kids brielle'
    });
}

function initHeaderCarousel() {
    $('#carouselContent').slick({
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        buttons: true,
        prevArrow: $('.slick-prevs'),
        nextArrow: $('.slick-nexts'),
        arrows: true,
    });
}

$('#newsCarousel').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    arrows: false,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1
            }
        }
    ]
});

function initNav() {
    var $btn_navigation = $('header .btn-nav');
    var $nav = $('nav');

    $btn_navigation.click(function (e) {
        e.stopPropagation();
        e.preventDefault();

        if ($(window).width() < 992) {
            $nav.toggleClass('toggle');
        }
    });

    if ($nav.find('ul').length > 0) {
        var $li = $nav.find('li');

        $li.has('ul').children('a').click(function (e) {

            $(this).closest('ul').children('li').find('ul').each(function (i, ul) {
                if ($(ul) != $(this).next('ul')) {
                    $(this).prev('a').children('span').removeClass('toggle');

                    if ($(window).width() < 1024) {
                        $(ul).slideUp();
                    }
                }
            });

            if ($(this).next('ul').css('visibility') === 'hidden' || $(this).next('ul').is(':hidden')) {
                e.preventDefault();

                if ($(window).width() < 1024) {
                    $(this).next('ul').slideDown();
                }
                $(this).children('span').addClass('toggle');
            }
        });
    }
    ;
}

